@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;600;700&display=swap');

//$text: #333333;
//$darkestBlue: #0A4361;
//$darkerBlue: #0C579F;
//$blue: #1170A0;
//$lightBlue: #199AF0;
//$grey: #EFF2F3;

body {
    font-family: 'Commissioner', sans-serif;
    font-size: 15px;
    color: $text;
}

.opacity {
    opacity: 0.8;
}

.brightness {
    filter: brightness(1.2);
    -webkit-filter: brightness(1.2);
}

.medium {
    font-weight: 500;
}

.blue {
    color: $blue;
}

.box-shadow {
    -webkit-box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.25) !important;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.25) !important;
}

img {
    flex-shrink: 0;
}

.darkerBlue {
    color: $darkerBlue;
}

.cookie-policy {
    background: $darkerBlue;
    color: white;
    padding: 15px 0;

    p {
        margin-bottom: 0;
        font-size: 1.1em;
    }
}

#header {
    padding-bottom: 45px;
    @include media-breakpoint-down(md) {
        padding-bottom: 0;
    }

    img.logo {
        max-height: 74px;
        margin-top: 63px;
        @include media-breakpoint-down(md) {
            display: block;
            margin: 33px auto 0;
        }

        &:hover {
            @extend .opacity;
        }
    }

    .top-nav {
        padding-top: 30px;
        padding-bottom: 19px;
        font-weight: 300;
        font-size: 0.95em;

        ul {
            list-style: none;
            padding-left: 0;

            li {
                margin-left: 20px;
                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                }

                &:first-child {
                    margin-left: 0;
                }

                a {
                    padding: 14px 6px 13px 4px;
                    color: $text;
                    display: block;

                    img {
                        height: 20px;
                        margin-right: 13px;

                        &.user-circle {
                            margin-right: 5px;
                            margin-top: -4px;
                        }
                    }

                    &:hover {
                        text-decoration: none;
                        @extend .opacity;
                    }

                    &.basket {
                        padding: 16px 26px 13px 21px;
                        background: #EDEDED;
                        margin-left: 10px;
                        @include media-breakpoint-down(md) {
                            margin-left: 0;
                        }
                    }

                    &.active {
                        color: $blue;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .nav {
        .navbar {
            padding: 0;
            font-size: 1.14em;
            //.navbar-toggler {
            //    border: 3px solid $darkerBlue;
            //    border-radius: 8px;
            //    @extend .box-shadow;
            //    span {
            //        background: $lightBlue;
            //        padding: 2px 0;
            //        width: 30px;
            //        height: 3px;
            //        content: ' ';
            //        display: block;
            //        margin: 5px 0;
            //    }
            //    &:hover {
            //        @extend .opacity;
            //    }
            //}
            .navbar-toggler {
                background: none;
                position: relative;
                border: 3px solid $darkerBlue;
                border-radius: 8px;
                padding: 10px 7px 5px;
                @extend .box-shadow;

                span {
                    border-radius: 5px;
                    background-color: #2C3E57;
                    padding: 2px 0;
                    display: block;
                    margin-bottom: 5px;
                    width: 35px;
                    //background-color: $blue;
                    transition: all 0.3s ease;

                    &:nth-child(1) {
                        transform: translate(0, 0);
                    }

                    &:nth-child(2) {

                    }

                    &:nth-child(3) {
                        transform: translate(0, 0);
                    }
                }

                &.active {
                    span {
                        &:nth-child(1) {
                            transform: translate(0, 8px) rotate(45deg);
                        }

                        &:nth-child(2) {
                            left: 60px;
                            opacity: 0;
                        }

                        &:nth-child(3) {
                            transform: translate(0, -10px) rotate(-45deg);
                        }
                    }
                }
            }

            .navbar-collapse {
                ul {
                    padding-left: 0;
                    @include media-breakpoint-down(md) {
                        padding: 0 0 30px;
                    }

                    li {
                        @include media-breakpoint-down(md) {
                            padding-bottom: 5px;
                        }

                        a {
                            padding: 0;
                            color: $text;
                            font-weight: 500;

                            &:hover {
                                text-decoration: none;
                                @extend .opacity;
                            }

                            &.active {
                                color: $blue;
                                background: transparent;
                                @extend .medium;
                            }
                        }

                        .dropdown-menu {
                            padding: 20px 30px;
                            @include media-breakpoint-down(md) {
                                padding: 15px 20px 9px;
                                border: none;
                            }

                            .dropdown-item {
                                padding-bottom: 6px;

                                &:hover, &:focus, &:active {
                                    background: white;
                                }

                                &.active {
                                    color: $blue;
                                    background: transparent;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

#template {
    .alert-overlay {
        padding-top: 10px;

        .alert {
            display: block;
            margin-bottom: 0;
        }
    }

    .banner {
        background: $darkerBlue;
        padding-top: 69px;
        padding-bottom: 65px;

        .breadcrumb {
            font-size: 0.91em;

            ul {
                padding: 0;
                list-style: none;

                li {
                    &:after {
                        content: '>';
                        padding-left: 8px;
                        padding-right: 10px;
                    }

                    &:last-child {
                        &:after {
                            content: '';
                        }
                    }

                    color: white;

                    a {
                        color: white;

                        &:hover {
                            text-decoration: none;
                            @extend .opacity;
                        }
                    }
                }
            }
        }

        h1 {
            color: white;
            font-size: 1.67em;
        }
    }

    .content {
        padding-top: 75px;
        padding-bottom: 89px;

        table {
            margin-top: 39px;

            thead {


                tr {
                    th {
                        background: $darkestBlue;
                        border: none;
                        color: white;
                        font-weight: 500;
                        padding: 15px 28px;
                        font-size: 0.96em;
                        @include media-breakpoint-down(sm){
                            padding: 10px 0;
                        }
                    }
                }
            }

            tbody {
                tr {


                    td {
                        background: white;
                        padding: 19px 28px;
                        border-top: 5px solid $grey;
                        vertical-align: middle;
                        font-size: 0.88em;

                        @include media-breakpoint-down(sm){
                            padding: 10px 0;
                        }

                        &.background-light-blue {
                            background: $lightBlue;
                            color: white;
                        }

                        h4 {
                            color: $darkerBlue;
                            font-weight: 500;
                            font-size: 1em;
                            margin-bottom: 0;
                        }

                        a.btn-primary.btn-sm {
                            padding: 6px 0;
                            width: 141px;
                            text-align: center;
                            font-size: 0.9em;

                            &.darkblue {
                                background: $darkestBlue;
                                border-color: $darkestBlue;
                            }
                        }
                    }
                }
            }
        }

        .main {
            margin-right: -24px;
            @include media-breakpoint-down(xl) {
                margin-right: 0;
            }
        }

        .bottom-border {
            border-bottom: 1px solid $grey;
            padding-bottom: 25px;
            margin-bottom: 25px;
        }

        h2 {
            font-size: 1.5em;
            padding-top: 5px;
            margin-bottom: 30px;
            @include media-breakpoint-down(sm) {
                font-size: 1.4em;
            }
        }

        h3 {
            font-size: 1.3em;
            font-weight: 500;
            line-height: 29px;
            color: $darkerBlue;
            margin-bottom: 25px;
            @include media-breakpoint-down(lg) {
                font-size: 1.15em;
            }
            @include media-breakpoint-down(sm) {
                font-size: 1.05em;
                line-height: 25px;
            }
        }

        h4 {
            font-size: 1.3em;
            margin-bottom: 30px;
        }

        h5 {

        }

        h6 {

        }

        a {
            color: $lightBlue;
            text-decoration: underline;
            font-weight: 500;

            &:hover {
                @extend .opacity;
            }

            &.btn.btn-primary {
                background: $lightBlue;
                border-color: $lightBlue;
                color: white;
                font-weight: 500;
                text-decoration: none;
                padding: 13px 45px 11px;
                border-radius: 8px;
                @include media-breakpoint-down(xl) {
                    padding: 13px 25px 11px;
                }

                &:hover {
                    text-decoration: none;
                    @extend .opacity;
                }
            }
        }

        p {
            margin-bottom: 25px;
            @include media-breakpoint-down(sm) {
                font-size: 0.9em;
            }
        }

        ul, ol {
            padding: 0;
            margin-left: 25px;
            margin-bottom: 25px;

            li {
                padding-bottom: 25px;
                @include media-breakpoint-down(sm) {
                    font-size: 0.9em;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                a {

                }
            }
        }

        ul {
            list-style: none;

            li {
                padding-left: 25px;
                //padding-bottom: 6px;
                position: relative;

                &:before {
                    color: $lightBlue;
                    content: "•";
                    position: absolute;
                    left: -2px;
                    top: -8px;
                    font-size: 2em;
                    line-height: 1;
                    height: 10px;
                }
            }
        }

        a.btn {
            -webkit-box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.25) !important;
            box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.25) !important;
        }

        hr {
            margin: 30px 0;
            border-color: $grey;
        }

        .grey-info-block {
            background: $grey;
            padding: 67px;
            margin: 54px 0;
            @include media-breakpoint-down(lg) {
                padding: 40px;
            }
            @include media-breakpoint-down(xs) {
                padding: 10px;
            }

            h2 {
                font-size: 1.4em;
                padding-top: 0;
                margin-bottom: 15px;
            }

            p {
                font-size: 0.83em;
                line-height: 24px;
                margin-bottom: 29px;
            }

            img {

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                &.map {
                    max-height: inherit;
                    margin-left: 0;
                }
            }
        }

        .border-top-text-block {
            border-top: 1px solid $grey;
            padding-top: 42px;
            margin-bottom: 45px;

            h2 {
                padding-bottom: 12px;
            }

            p {
                margin-bottom: 37px;
            }
        }

        .nav.nav-tabs {
            margin-left: 0;
            margin-bottom: 0;
            border-bottom: none;
            list-style: none;
            padding: 0;

            li {
                max-width: 49%;
                text-align: center;
                font-size: 1.12em;
                padding: 0;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    width: 50%;
                    max-width: inherit;
                }

                &:before {
                    content: '';
                }

                &:nth-child(1) {
                    background: $blue;
                    border-color: $blue;

                    a {
                        &.active {
                            &:after {
                                border-top: 25px solid $blue;
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    background: $darkerBlue;
                    border-color: $darkerBlue;

                    a {
                        &.active {
                            &:after {
                                border-top: 25px solid $darkerBlue;
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    background: $darkestBlue;
                    border-color: $darkestBlue;

                    a {
                        &.active {
                            &:after {
                                border-top: 25px solid $darkestBlue;
                            }
                        }
                    }
                }

                &:hover {
                    opacity: 1;

                    a {
                        opacity: 1;
                    }
                }

                a {
                    color: white;
                    text-decoration: none;
                    padding: 18px 5px 16px;
                    position: relative;
                    border-radius: 0;
                    @include media-breakpoint-down(xl) {
                        font-size: 0.9em;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 1em;
                    }
                    @include media-breakpoint-down(xs) {
                        font-size: 0.8em;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        width: 0;
                        height: 0;
                        border-right: 25px solid transparent;
                        border-bottom: 25px solid transparent;
                        border-left: 25px solid transparent;
                        display: none;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        top: calc(100% + 25px);
                    }

                    &.active {
                        background-color: transparent;
                        border-color: transparent;

                        &:hover {
                            opacity: 1;
                            filter: brightness(1);
                            -webkit-filter: brightness(1);
                        }

                        &:after {
                            z-index: 9999999;
                            display: inherit;

                            &:hover {
                                opacity: 1;
                                filter: brightness(1);
                                -webkit-filter: brightness(1);
                            }
                        }
                    }

                    &:hover {
                        text-decoration: none;
                        opacity: 1;
                        @extend .brightness;
                    }
                }
            }

            &.triple {
                li {
                    max-width: 32%;
                }
            }
        }

        .form-group {
            input, select, textarea, .form-control {
                border-radius: 7px;
            }
            input, select {
                height: 60px;
            }
            input[type=checkbox], input[type=radio] {
                height: auto;
            }

            textarea {
                //margin-bottom: 34px;
                height: 247px;
            }

            button {
                font-weight: 500;
                font-size: 1.2em;
                padding: 17px 51px;
                border-radius: 7px;
            }
        }

        .order {
            .form-group {
                margin-bottom: 10px;
            }

            button.btn {
                padding: 12px 20px 11px;
            }
        }

        .tab-content {
            .grey-info-block {
                margin-top: 14px;

                h4 {
                    font-size: 1.15em;
                    margin-bottom: 22px;
                }

                h5 {
                    font-size: 1.07em;
                    padding-bottom: 40px;
                    color: #1C719E;
                }

                p {
                    margin-bottom: 35px;
                    font-size: 1em;
                }

                ul, ol {
                    padding-top: 7px;

                    li {
                        padding-bottom: 5px;
                    }
                }

                .video {
                    padding-bottom: 30px;
                    //TODO::REMOVE THE IMG STYLES BELOW
                    img {
                        max-width: 100%;
                        width: auto;
                        height: auto;
                        max-height: inherit;
                        margin: 0;
                        display: block !Important;
                    }
                }

                .image {
                    max-width: 100%;
                    width: auto;
                    height: auto;
                    max-height: inherit;
                    margin: 20px 0 50px;
                }

                .map {
                    @include media-breakpoint-down(lg) {
                        padding-top: 30px;
                    }

                    iframe {
                        height: 222px;
                        width: 100%;
                    }
                }

                a.btn.btn-primary {
                    padding: 15px 0 14px;
                    width: 100%;
                    max-width: 321px;
                    border-radius: 4px;
                    font-size: 1.05em;
                    @include media-breakpoint-down(sm) {
                        font-size: 1em;
                    }
                }

                &.has-table {
                    padding: 62px 44px;
                    @include media-breakpoint-down(sm) {
                        padding: 30px 20px;
                    }

                    .text {
                        overflow: hidden;
                    }

                    .table-holder {
                        @include media-breakpoint-down(sm) {
                            overflow-x: scroll;
                        }
                    }


                }
            }
        }

        .course-filter {
            list-style: none;
            padding: 26px 0 22px;

            li {
                margin-right: 15px;
                font-size: 0.88em;
                border: 1px solid $grey;
                padding: 7px 25px;
                @include media-breakpoint-down(sm) {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:before {
                    content: '';
                }

                &:hover {
                    @extend .opacity;
                    cursor: pointer;
                }

                &.active {
                    background: $lightBlue;
                    color: white;

                    &:hover {
                        pointer-events: none;
                        opacity: 1;
                        cursor: inherit;
                    }
                }
            }
        }

        .courses {
            padding-bottom: 50px;

            .course {
                margin-bottom: 34px;
                -webkit-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.3);
                -moz-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.3);
                box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.3);

                .left {
                    padding: 43px 0 45px 56px;
                    @include media-breakpoint-down(lg) {
                        padding: 30px;
                    }

                    h3 {
                        font-size: 1.185em;
                        margin-bottom: 17px;
                    }

                    p {
                        font-size: 0.95em;
                        margin-bottom: 18px;
                    }

                    .cred {
                        img {
                            max-height: 47px;
                            margin-right: 13px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    .type {
                        img {
                            max-height: 47px;
                        }
                    }
                }

                .right {
                    background: $grey;
                    padding: 46px 27px 48px 30px;
                    font-size: 0.87em;
                    margin-left: 52px;
                    @include media-breakpoint-down(xl) {
                        margin-left: 22px;
                    }
                    @include media-breakpoint-down(lg) {
                        padding: 30px;
                        margin-left: 0;
                    }

                    p {
                        margin-bottom: 5px;
                    }

                    a.btn {
                        border-radius: 5px;
                        padding: 8px 42px;
                        @include media-breakpoint-down(lg) {
                            margin-top: 24px;
                        }
                    }
                }
            }
        }

        .reviews {
            padding-bottom: 36px;

            .review {
                background: $grey;
                padding: 48px 67px;
                margin-bottom: 26px;
                @include media-breakpoint-down(md) {
                    padding: 30px;
                }

                h5 {
                    font-weight: 300;
                    font-size: 1.06em;
                    line-height: 23px;
                    color: $blue;
                }

                p {
                    font-size: 0.95em;
                    padding-top: 12px;
                    margin-bottom: 0;
                }
            }
        }

        .accordion {
            border: none;
            padding-bottom: 40px;

            .card {
                border: none;
                margin-bottom: 7px;
                border-radius: 0;

                .card-header {
                    margin-bottom: 0;
                    padding: 0;
                    border: none;
                    background-color: transparent;

                    h2 {
                        margin-top: 0;

                        button {
                            &:after {
                                content: url('../images/angle-down.svg');
                                display: inline-block;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                width: 16px;
                                height: 16px;
                                position: absolute;
                                top: 25px;
                                right: 29px;
                                transform: rotate(180deg);
                                transition: all 0.3s linear;
                                transform-origin: center;
                            }

                            &:hover {
                                text-decoration: none;
                                @extend .opacity;
                            }

                            &:active, &:focus {
                                text-decoration: none;
                            }
                        }

                        button, span {
                            border: none;
                            background: $darkestBlue;
                            width: 100%;
                            padding: 21px 59px 18px 29px;
                            font-size: 0.6em;
                            color: white;
                            font-weight: 500;
                            position: relative;
                            border-radius: 0;
                            @include media-breakpoint-down(sm) {
                                font-size: 0.7em;
                            }


                            &.collapsed {
                                &:after {
                                    transform: rotate(0deg);
                                    top: 18px;
                                }
                            }
                        }
                    }
                }

                .card-body {
                    background-color: #EFF2F3;
                    padding: 52px 47px 52px;

                    .error {
                        color: red;
                    }

                    h4 {
                        margin-bottom: 20px;
                    }

                    p {
                        span.blue {
                            font-weight: bold;
                            color: $blue;
                        }
                    }

                    .question {
                        padding-bottom: 30px;

                        &:last-child {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }

        .pagination-holder {
            text-align: center;
            padding-top: 27px;
            padding-bottom: 35px;

            ul {
                list-style: none;
                padding: 0;
                width: 100%;

                li {
                    display: inline-block;
                    margin: 0 2px;
                    padding: 0;

                    &:before {
                        content: '';
                    }

                    a {
                        padding: 11px 20px 14px;
                        cursor: pointer;
                        text-decoration: none;
                        font-weight: normal;
                        background: #EFF2F3;
                        color: $text;

                        &:hover {
                            @extend .opacity;
                            text-decoration: none;
                        }

                        img {
                            height: 11px;
                        }
                    }

                    span {
                        padding: 11px 20px 14px;
                        color: white;
                        background: $darkerBlue;
                    }
                }
            }
        }

        label {
            font-weight: 500;
            margin-bottom: 0;
            margin-right: 15px;
        }

        .form-group {
            margin-bottom: 0;

            .form-control, input[type="text"], input[type="email"], input[type="tel"], input[type="password"], input[type="number"], select, textarea {
                height: 58px;
                margin: 6px 0;

                &:is(span) {
                    line-height: 2.7rem;
                }

                &:is(textarea) {
                    height: 239px;
                    text-indent: 0;
                }

                &:is(select) {
                    position: relative;
                    padding-right: 50px;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                }

                &::placeholder {
                    color: $text;
                    opacity: 1;
                }

                &:-ms-input-placeholder {
                    color: $text;
                }

                &::-ms-input-placeholder {
                    color: $text;
                }
            }
        }

        .select-holder {
            position: relative;

            &:after {
                content: url('../images/chevrons.svg');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 16px;
                height: 16px;
                z-index: 5;
                position: absolute;
                top: 18px;
                right: 17px;
                pointer-events: none;
            }
        }

        .member-downloads {
            .input-group {
                input {
                    height: 60px;
                    text-indent: 11px;
                }
                button {
                    border-color: $darkerBlue;
                    background: $darkerBlue;
                    color: white;
                    &:hover {
                        @extend .brightness;
                    }

                }
            }
            .download {
                border-bottom: 2px solid #EFF2F3;
                padding-bottom: 29px;
                margin-bottom: 29px;
                @include media-breakpoint-down(sm) {
                    text-align: center;
                }

                .img {
                    padding-right: 24px;
                    @include media-breakpoint-down(sm) {
                        padding: 0;
                    }

                    img {
                        max-width: 150px;
                        height: auto;
                        display: block;
                        @include media-breakpoint-down(sm) {
                            margin: 0 auto 24px;
                        }
                    }
                }

                .text {
                    @include media-breakpoint-down(sm) {
                        margin: 0 0 24px;
                        padding: 0 40px;
                    }
                }

                .link {
                    color: $blue;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            &.waterline {
                .img {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    #sidebar {
        .grey-menu {
            background: $grey;
            margin-bottom: 20px;

            ul {
                list-style: none;
                padding: 36px 42px;
                @include media-breakpoint-down(xl) {
                    padding: 36px 39px;
                }
                @include media-breakpoint-down(sm) {
                    padding: 30px;
                }

                li {
                    padding: 15px 5px 13.5px 0;
                    font-size: 1em;
                    border-bottom: 1px solid $lightBlue;

                    &:last-child {
                        border-bottom: none;
                    }
                    &:hover {
                        @extend .opacity;
                    }

                    &:before {
                        content: '';
                    }

                    a {
                        color: $text;
                        font-weight: 500;
                        text-decoration: none;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &.active {
                        padding: 15px 5px 14px 19px;
                        background-color: $lightBlue;
                        border-color: $lightBlue;

                        a {
                            color: white;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .price-block {
            background: $grey;
            margin-bottom: 20px;
            padding: 52px 45px 59px;
            @include media-breakpoint-down(sm) {
                padding: 30px 20px;
            }

            .date {
                font-weight: 500;
                font-size: 0.9em;
                color: $lightBlue;
                margin-bottom: 15px;

                span {
                    font-size: 2.7em;
                    padding-right: 11px;
                }

                &.no-date {
                    padding-top: 31px;
                    margin-bottom: 24px;
                }
            }

            h3 {
                font-size: 1.186em;
                margin-bottom: 17px;
            }

            p {
                font-size: 0.87em;
                margin-bottom: 5px;
            }

            a.btn {
                text-align: center;
                width: 100%;
                padding: 8px 0;
                text-decoration: none;
                font-size: 0.89em;

                &:hover {
                    text-decoration: none;
                    @extend .opacity;
                }

                &.btn-primary {
                    background: $lightBlue;
                    color: white;
                    margin-top: 20px;
                }

                &.btn-trans {
                    margin-top: 7px;
                    border-color: $lightBlue;
                    padding: 8px 0;
                }
            }
        }

        .color-block {
            margin-bottom: 20px;
            color: white;
            padding: 39px 42px 43px;
            @include media-breakpoint-down(lg) {
                display: none;
            }

            h3 {
                color: white;
                font-size: 1.1em;
                margin-bottom: 8px;
                line-height: 22px;
            }

            p {
                font-size: 0.85em;
                padding-top: 4px;
                line-height: 20px;
                margin-bottom: 18px;
            }

            a.btn.btn-trans {
                border-color: white;
                color: white;
                background: transparent;
                font-size: 0.7em;
                padding: 7px 40px;
                text-decoration: none;
                font-weight: 400;
                @include media-breakpoint-down(xl) {
                    padding: 7px 30px;
                }
            }

            &.light-blue {
                background: $blue;
            }

            &.blue {
                background: $darkerBlue;
            }

            &.dark-blue {
                background: $darkestBlue;
            }
        }

        .booking-price {
            margin-left: 47px;
            padding: 58px 57px 23px;
            @include media-breakpoint-down(md) {
                margin-left: 0;
                margin-top: 40px;
            }
            @include media-breakpoint-down(lg) {
                margin-left: 0;
                padding: 30px;
            }

            &.color-block {
                @include media-breakpoint-down(xl) {
                    display: inherit !important;
                }
            }

            h3 {
                font-size: 1.2em;
                line-height: 26px;
                margin-bottom: 28px;
            }

            p {
                font-size: 1.05em;
                padding-top: 2px;
                line-height: 30px;
                margin-bottom: 29px;

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    &.home {
        .top {
            background-size: cover;
            background-position: right bottom;
            background-repeat: no-repeat;
            background-image: url('../images/homepage-background.jpg');
            @include media-breakpoint-down(lg) {
                background-position: -1400px bottom;
            }
            @include media-breakpoint-down(md) {
                background-position: -2880px bottom;
            }

            h1 {
                color: white;
                font-weight: 500;
                font-size: 2.21em;
                padding-top: 153px;
                line-height: 44px;
                padding-bottom: 83px;
            }

            .blocks {
                padding-bottom: 0;
                margin-bottom: 119px;

                .block {
                    background: white;
                    border-radius: 8px;
                    @extend .box-shadow;
                    padding: 57px 49px 60px 40px;
                    @include media-breakpoint-down(lg) {
                        padding: 57px 40px 60px;
                    }

                    @include media-breakpoint-down(lg) {
                        margin-bottom: 30px;
                    }

                    h2 {
                        font-size: 1.68em;
                        margin-bottom: 33px;
                        line-height: 35px;
                    }

                    p {
                        font-size: 1.14em;
                        line-height: 28px;
                        padding-bottom: 16px;

                        strong {
                            color: $blue;
                        }
                    }

                    a.btn {
                        border-radius: 8px;
                        min-width: 246px;
                        font-size: 1.3em;
                        padding: 16px 0;
                        @extend .box-shadow;
                        @include media-breakpoint-down(lg) {
                            min-width: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .two-blocks {
            margin-bottom: 119px;

            h2 {
                font-size: 1.48em;
                font-weight: 500;
                line-height: 29px;
                padding-bottom: 24px;
            }

            p {
                font-size: 1.08em;
                line-height: 25px;
                padding-bottom: 13px;
            }

            a.btn {
                min-width: 323px;
                padding: 12px 0;
            }

            .image {
                @include media-breakpoint-down(md) {
                    margin-top: 40px;
                }
            }
        }

        .upcoming {
            &.grey {
                background: #EFF2F3;

                .events {
                    .event {
                        background: white;
                    }
                }
            }

            padding: 117px 0 120px;
            @include media-breakpoint-down(lg) {
                padding: 117px 0 96px;
            }

            .titles {
                padding-bottom: 60px;

                h4 {
                    margin-bottom: 0;
                    margin-top: 12px;
                    font-size: 1.74em;
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 25px;
                    }
                }

                .btn.btn-trans {
                    border: 2px solid $blue;
                    color: $blue;
                    border-radius: 8px;
                    font-weight: bold;
                    @extend .box-shadow;
                    min-width: 249px;
                    font-size: 1.13em;
                    padding: 12px 0 13px;

                    &:hover {
                        @extend .opacity;
                    }
                }
            }

            .events {
                a {
                    &:hover {
                        text-decoration: none;
                        @extend .opacity;
                    }

                    .event {
                        padding: 33px 33px 17px;
                        background: #EFF2F3;

                        &:hover {
                            text-decoration: none;
                            @extend .opacity;
                        }

                        @include media-breakpoint-down(lg) {
                            margin-bottom: 24px;
                        }

                        .date {
                            font-weight: 700;
                            font-size: 1em;
                            color: $lightBlue;
                            margin-bottom: 9px;

                            span {
                                font-size: 2.8em;
                                padding-right: 14px;
                            }
                        }

                        .title {
                            font-size: 1.47em;
                            margin-bottom: 22px;
                            font-weight: bold;
                            line-height: 31px;
                            color: $darkerBlue;
                        }

                        .type {
                            font-weight: bold;
                            color: $lightBlue;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .comfort_join {
            background-color: #001850;
            padding: 100px 0 76px;
            background-image: url('../images/homepage-hands.png');
            background-size: contain;
            background-position: -100px center;
            background-repeat: no-repeat;
            @include media-breakpoint-down(xl) {
                background-position: -210px center;
            }
            @include media-breakpoint-down(lg) {
                padding: 50px 0 16px;
            }
            @include media-breakpoint-down(md) {
                background-image: none;
            }

            h3 {
                font-size: 2.07em;
                font-weight: 700;
                color: white;
            }

            p {
                font-size: 1.27em;
                font-weight: 500;
                padding-top: 24px;
                line-height: 27px;
                color: white;
                margin-bottom: 43px;
            }

            .bottom {
                a.btn {
                    min-width: 246px;
                    padding: 14px 0;
                    font-size: 1.43em;
                    border-radius: 8px;
                    margin-right: 33px;
                }

                p {
                    padding-top: 12px;
                    font-size: 0.95em;
                    line-height: 19px;
                    color: white;
                    @include media-breakpoint-down(lg) {
                        padding-top: 0;
                    }
                    @include media-breakpoint-down(md) {
                        padding-top: 12px;
                    }
                    @include media-breakpoint-down(xs) {
                        padding-top: 24px;
                    }
                }
            }
        }
    }

    &.page.course {
        .content {
            .main {
                h2 {
                    font-size: 1.42em;
                    padding-top: 2px;
                    line-height: 31px;
                    margin-bottom: 46px;
                }

                .cred {
                    padding-top: 3px;
                    width: 215px;

                    img {
                        max-height: 71px;
                        margin-left: 24px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }

                .course-image {
                    padding-bottom: 34px;
                }
            }
        }
    }

    &.page.cpd {
        table {
            @extend .box-shadow;
            @include media-breakpoint-down(md) {
                margin-bottom: 60px;
            }

            tr {
                &:nth-of-type(odd) {
                    background: $grey;
                }

                td {
                    padding: 15px 28px;
                    font-size: 0.84em;
                    vertical-align: middle;
                    line-height: 23px;
                    border: none;

                    a.btn {
                        padding: 6px 26px;
                        height: 35px;
                    }
                }
            }
        }
    }

    &.page.recognition {
        .cpd-logo {
            max-height: 200px;
            @include media-breakpoint-down(xs) {
                margin-bottom: 31px;
            }
        }

        #sidebar {
            @include media-breakpoint-down(md) {
                margin-top: 40px;
            }
        }
    }

    &.page.publications {
        .input-group {
            input {
                height: 60px;
                text-indent: 11px;
            }
            button {
                border-color: $darkerBlue;
                background: $darkerBlue;
                color: white;
                &:hover {
                    @extend .brightness;
                }

            }
        }
        .publications-list {
            padding-top: 41px;

            .publication {
                border-bottom: 1px solid $grey;
                padding-bottom: 51px;
                margin-bottom: 64px;

                img {
                    max-height: 180px;
                    @include media-breakpoint-down(xs) {
                        margin-bottom: 30px;
                    }
                }

                .text {

                    h4 {
                        font-size: 1.12em;
                        margin-bottom: 21px;
                    }

                    p {
                        //font-size: 0.88em;
                        line-height: 23px;
                    }
                }
            }
        }

        .publications-table {
            margin-top: 66px;
            width: 100%;

            tr {
                width: 100%;

                td {
                    vertical-align: middle;
                    padding: 14px 30px;
                    font-size: 0.942em;
                    @include media-breakpoint-down(sm) {
                        padding: 14px 10px;
                        min-width: 100px;
                    }

                    a.btn {
                        @include media-breakpoint-down(sm) {
                            padding: 6px 15px;
                        }
                    }
                }
            }
        }

        &.purchase {
            .image {
                @include media-breakpoint-down(xs) {
                    display: block;
                    margin: 0 auto;
                }
            }

            .right {
                padding-left: 41px;
                padding-top: 3px;
                @include media-breakpoint-down(xs) {
                    padding-left: 0;
                    padding-top: 30px;
                }
            }

            div.top {
                border-bottom: 1px solid #d9d9d9;
                margin-bottom: 20px;
                padding-bottom: 35px;

                .buttons {
                    padding-right: 0;
                    padding-left: 26px;
                    @include media-breakpoint-down(lg) {
                        padding-left: 0;
                    }
                    @include media-breakpoint-down(md) {
                        margin-top: 11px;
                    }

                    a.btn {
                        padding: 14px 5px 15px;
                        font-size: 1.09em;
                        border-radius: 4px;

                        img {
                            max-height: 20px;
                            margin-right: 15px;
                            width: 15px;
                            margin-top: -3px;
                            @include media-breakpoint-down(lg) {
                                margin-right: 5px;
                            }
                        }

                        &:last-child {
                            margin-top: 10px;
                        }

                        &.darker {
                            background: $darkestBlue;
                            border-color: $darkestBlue;
                        }
                    }
                }

                .titles {
                    border-right: 1px solid #D9D9D9;
                    margin-right: 19px;
                    padding-right: 59px;
                    @include media-breakpoint-down(xl) {
                        margin-right: 0;
                        padding-right: 15px;
                    }
                    @include media-breakpoint-down(md) {
                        border-right: none;
                        padding-right: 0;
                    }

                    h4 {
                        font-size: 1.3em;
                        margin-bottom: 32px;
                    }

                    p {
                        font-size: 1.06em;
                        margin-bottom: 17px;
                        @include media-breakpoint-down(lg) {
                            font-size: 1em;
                        }
                    }
                }
            }

            .text {
                padding-top: 29px;
                font-size: 0.94em;

                p {
                    margin-bottom: 22px;
                }
            }

            .related {
                background: #EFF2F3;
                padding: 52px 60px 92px;
                margin-top: 63px;
                margin-bottom: 47px;
                @include media-breakpoint-down(md) {
                    padding: 52px 60px 48px;
                }
                @include media-breakpoint-down(xs) {
                    padding: 30px 30px 0;
                }

                .publications {
                    padding-top: 17px;

                    a {
                        text-decoration: none;

                        &:hover {
                            text-decoration: none;
                            @extend .opacity;
                        }
                    }

                    .publication {
                        &:hover {
                            @extend .opacity;
                        }

                        @include media-breakpoint-down(md) {
                            margin-bottom: 44px;
                        }

                        p.title {
                            padding-left: 21px;
                            line-height: 23px;
                            color: $text;
                            text-decoration: none;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &.news {
        .post {
            border-bottom: 1px solid #E5E5E5;
            padding-bottom: 21px;
            margin-bottom: 49px;

            h4 {
                margin-bottom: 20px;
                line-height: 30px;
            }

            .image {
                margin-left: 47px;
                border: 1px solid #E5E5E5;
                padding: 17px 24px;
                @include media-breakpoint-down(xl) {
                    margin-left: 20px;
                }
                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                }
                @include media-breakpoint-down(xs) {
                    text-align: center;
                    padding: 30px;
                    margin-bottom: 0;
                    border: none;
                }
            }
        }

        .post-content {
            margin-bottom: 60px;
        }

        .back {
            font-size: 1.06em;
            padding-bottom: 48px;
            display: inline-block;
        }
    }

    .qa-holder {
        .search {
            margin: 40px 0;
        }
        input[type="text"] {
            height: 60px;
            border-radius: 7px;
            text-indent: 11px;

            &::placeholder {
                color: $text;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $text;
            }

            &::-ms-input-placeholder {
                color: $text;
            }
        }
        .results-text {
            margin-top: 10px;
            font-size: 1.2em;
        }
    }

    &.about {
        .video {
            padding: 30px 0 46px;
        }
    }

    &.industry-links {
        .company-holder {
            margin-top: 41px;
            border-top: 1px solid #E5E5E5;

            .company {
                padding: 46px 0 31px;
                border-bottom: 1px solid #E5E5E5;

                .image {
                    border: 1px solid #E5E5E5;
                    padding: 30px 48px;
                    @include media-breakpoint-down(xs) {
                        margin-bottom: 30px;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                    }

                    img {
                        @include media-breakpoint-down(xs) {
                            display: block;
                            margin: 0 auto;
                        }
                    }
                }

                .description {
                    padding-left: 20px;
                    @include media-breakpoint-down(xs) {
                        padding-left: 0;
                    }

                    h4 {
                        font-size: 1.13em;
                        margin-bottom: 19px;
                    }

                    p {
                        margin-bottom: 6px;
                        font-weight: bold;
                        color: $darkerBlue;
                        //font-size: 0.88em;

                        a {
                            color: $text;
                            font-weight: normal;
                            text-decoration: none;

                            &:hover {
                                text-decoration: none;
                                @extend .opacity;
                            }
                        }
                    }
                }
            }
        }

        #sidebar {
            @include media-breakpoint-down(md) {
                margin-top: 60px;
            }
        }
    }

    &.tutors {
        .tutors-holder {
            > h4 {
                margin-bottom: 49px;
            }

            .tutor {
                border-bottom: 1px solid #E5E5E5;
                padding-bottom: 25px;
                margin-bottom: 50px;

                .info {
                    //padding-bottom: 44px;

                    h4 {
                        font-size: 1.13em;
                        margin-bottom: 13px;
                    }

                    h5 {
                        font-size: 0.83em;
                        font-weight: lighter;
                        color: $blue;
                    }

                    a.btn {
                        border: 1px solid $lightBlue;
                        padding: 6px 19px;
                        font-weight: 500;
                        border-radius: 5px;
                        text-decoration: none;

                        &:hover {
                            @extend .opacity;
                            text-decoration: none;
                        }
                    }
                }

                .image {
                    padding-left: 12px;
                }
            }
        }

        .profile {
            padding-bottom: 84px;

            .text {
                padding-right: 60px;
                @include media-breakpoint-down(md) {
                    padding-right: 0;
                }

                h2 {
                    font-size: 1.43em;
                    margin-bottom: 11px;
                }

                h5 {
                    font-size: 0.83em;
                    font-weight: lighter;
                    color: $blue;
                    margin-bottom: 36px;
                }

                a.btn {
                    border: 1px solid $lightBlue;
                    padding: 8px 49px;
                    font-weight: 500;
                    border-radius: 5px;
                    text-decoration: none;
                    margin-top: 23px;

                    &:hover {
                        @extend .opacity;
                        text-decoration: none;
                    }
                }
            }

            .image {
                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }
            }
        }

    }

    &.external-courses {
        .external {
            padding-top: 33px;
            padding-bottom: 50px;

            .course {
                margin-bottom: 30px;
                -webkit-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
                -moz-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
                box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
                border: 1px solid rgba(0, 0, 0, 0.1);

                .text {
                    padding: 30px 60px;
                    @include media-breakpoint-down(xl) {
                        padding: 30px 60px;
                    }
                    @include media-breakpoint-down(md) {
                        padding: 30px;
                    }

                    h3 {
                        margin-bottom: 30px;
                    }

                    p {
                        margin-bottom: 20px;
                    }
                }

                .grey-bg {
                    margin-left: 34px;
                    background: $grey;
                    text-align: center;
                    padding-top: 30px;
                    @include media-breakpoint-down(xl) {
                        margin-left: 0;
                        padding: 30px 0;
                    }
                    @include media-breakpoint-down(md) {
                        padding: 30px;
                        text-align: left;
                        margin-left: 0;
                    }

                    a.btn {
                        padding: 8px 40px 8px;
                    }
                }
            }
        }
    }

    &.page.contact {
        p {
            line-height: 29px;
        }

        .boxes {
            margin-top: 25px;

            .box {
                background: $grey;
                text-align: center;
                padding: 44px 0;
                margin-bottom: 20px;

                h4 {
                    font-size: 1.4em;
                    margin-bottom: 11px;
                }

                a.email {
                    color: $lightBlue;
                    font-weight: 500;
                    text-decoration: none;
                    display: inline-block;
                    margin-bottom: 26px;

                    &:hover {
                        text-decoration: none;
                        @extend .opacity;
                    }
                }

                a.btn {
                    color: white;
                    padding: 15px 32px 16px;
                }
            }
        }

        &.form {
            .card-body {
                padding: 53px 111px;
                @include media-breakpoint-down(lg) {
                    padding: 53px;
                }
                @include media-breakpoint-down(md) {
                    padding: 30px;
                }

                input, select {
                    height: 60px;
                }

                input[type="checkbox"] {
                    height: 22px !important;
                }

                textarea {
                    height: 247px;
                }

                button {
                    font-weight: 500;
                    font-size: 1.2em;
                    padding: 17px 51px;
                }
            }
        }
    }

    &.page.events {
        .event-holder {
            padding-bottom: 63px;

            h4 {
                margin-bottom: 39px;
            }

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }

            .event {
                background: $grey;
                padding: 50px 42px 25px 45px;
                margin-bottom: 24px;

                &:hover {
                    @extend .opacity;
                }

                .date {
                    font-weight: 500;
                    font-size: 0.9em;
                    color: $lightBlue;
                    margin-bottom: 15px;

                    span {
                        font-size: 2.7em;
                        padding-right: 11px;
                    }

                    &.no-date {
                        padding-top: 31px;
                        margin-bottom: 24px;
                    }
                }

                .title {
                    font-weight: bold;
                    font-size: 1.3em;
                    line-height: 29px;
                    margin-bottom: 11px;
                    color: $darkerBlue;
                }

                .description {
                    font-size: 0.9em;
                    line-height: 24px;
                    color: $text;
                }

                .type {
                    font-weight: 500;
                    font-size: 0.9em;
                    padding-top: 1px;
                    color: $lightBlue;
                    text-transform: uppercase;
                }
            }
        }
    }

    &.page.events.event {
        .event-image {
            margin-top: 42px;
            margin-bottom: 50px;

            img {
                border: 2px solid $grey;
            }
        }

        .sponsors-holder {
            padding-top: 30px;

            h4 {
                padding-bottom: 9px;
            }

            .sponsors {
                .sponsor {
                    border: 2px solid $grey;
                    padding: 24px 24px;
                    text-align: center;
                    margin-bottom: 24px;

                    img {
                        display: block;
                        margin: 0 auto;
                        max-height: 100px;
                    }
                }
            }
        }
    }

    &.page.waterline {

        .top-block {
            background: $grey;
            padding: 60px 0 115px;

            h2 {
                padding-top: 31px;
                font-size: 1.9em;
                font-weight: 500;
                color: $darkerBlue;
                margin-bottom: 31px;
            }

            p {
                font-size: 1.07em;
                line-height: 28px;
            }

            img {
                display: block;
                margin: 0 0 0 auto;
                @include media-breakpoint-down(md) {
                    margin: 60px auto 0;
                }
            }

            ul.ticks {
                list-style: none;
                padding-left: 0;
                padding-top: 17px;
                font-size: 1.06em;
                margin-bottom: 43px;

                li {
                    margin-bottom: 11px;

                    img {
                        max-height: 20px;
                        margin-right: 18px;
                        margin-top: 3px;
                    }

                    a {
                        color: $lightBlue;
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }
            }

            a.btn {
                padding: 16px 45px 15px;
                font-size: 1.07em;
                background: $lightBlue;
                border-color: $lightBlue;
                @extend .box-shadow;
                @include media-breakpoint-down(lg) {
                    padding: 16px 37px 15px;
                }
                @include media-breakpoint-down(sm) {
                    margin-bottom: 30px;
                }

            }
        }

        .bottom-block {
            padding: 97px 0;

            h2 {
                font-size: 1.42em;
                margin-bottom: 38px;
            }

            h5 {
                font-size: 1.15em;
                line-height: 25px;
                margin-bottom: 27px;
                color: $darkerBlue;
                font-weight: bold;
            }

            p {
                padding-bottom: 20px;
                font-size: 1.06em;
                margin-bottom: 27px;

                a {
                    font-weight: bold;
                    color: $lightBlue;
                    text-decoration: underline;
                }
            }

            .table-block {
                padding-top: 33px;
                overflow: hidden;

                .table-holder {
                    @include media-breakpoint-down(md) {
                        margin-bottom: 60px;
                    }
                    @include media-breakpoint-down(xs) {
                        overflow-x: scroll;
                    }

                    table {
                        @extend .box-shadow;

                        thead {
                            background: $darkestBlue;
                            color: white;

                            th {
                                padding: 16px 30px 15px;
                                font-size: 0.93em;
                                font-weight: normal;
                            }
                        }

                        tbody {
                            border: none;

                            tr {
                                border: none;

                                td {
                                    border: none;
                                    padding: 23px 29px 20px;
                                }
                            }
                        }
                    }

                    .text-block {
                        padding-top: 16px;

                        h5 {
                            color: $text;
                            font-size: 1em;
                            line-height: 30px;
                            margin-bottom: 16px;
                        }

                        p {
                            font-size: 0.88em;
                            line-height: 24px;
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }

    &.page.membership {
        .top-block {
            padding-bottom: 60px;

            .text {
                h2 {
                    margin-bottom: 25px;
                }

                h3 {
                    font-size: 1.15em;
                    line-height: 26px;
                }

                @include media-breakpoint-down(md) {
                    margin-top: 40px;
                }
            }

            .img-holder {
                margin-left: 24px;
                padding: 30px;
                border: 1px solid $grey;
                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    //margin-top: 15px;
                    width: 100%;
                }
            }
            iframe {
                height: 300px;
                width: 100%;
            }
        }

        .border-top-text-block {
            padding-top: 60px;
            margin-bottom: 60px;
        }

        .pills {
            padding-top: 15px;

            .nav-pills {
                border: 1px solid $grey;
                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }

                .nav-link {
                    border-bottom: 1px solid $grey;
                    border-radius: 0;
                    color: $text;
                    text-decoration: none;
                    font-size: 0.9em;
                    padding: 11px 20px 13px;

                    &:hover {
                        text-decoration: none;
                        @extend .opacity;
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    &.active {
                        background: $lightBlue;
                        color: white;
                    }
                }
            }

            .tab-content {
                .tab-pane {
                    padding-left: 6px;

                    h2 {
                        padding-top: 0;
                        margin-bottom: 9px;
                        color: $darkerBlue;
                    }

                    h5 {
                        margin-bottom: 21px;
                        font-size: 1.1em;

                        strong {
                            font-weight: 500;
                        }
                    }

                    p {
                        margin-bottom: 25px;
                    }

                    a.btn {
                        border: 1px solid $lightBlue;
                        color: white;
                        background: $lightBlue;
                        padding: 9px 14px;
                        text-decoration: none;

                        &:hover {
                            text-decoration: none;
                            @extend .opacity;
                        }
                    }
                }
            }
        }

        ul.ticks {
            list-style: none;
            padding-left: 0;
            padding-top: 17px;
            font-size: 1.06em;
            margin-bottom: 43px;

            li {
                margin-bottom: 0;
                padding-left: 0;
                padding-bottom: 8px;

                &:before {
                    display: none;
                    content: '';
                }

                img {
                    max-height: 20px;
                    margin-right: 18px;
                    margin-top: 3px;
                }

                a {
                    color: $lightBlue;
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
        }

        &.register {
            input[type="file"] {
                padding-top: 15px;
                height: inherit;
                margin: 0;
            }

            textarea {
                height: 100px;
            }
        }
    }

    &.page.training.booking {
        .quick-login {
            border-top: 1px solid $grey;
            border-bottom: 1px solid $grey;
            margin: 62px 0;
            padding: 61px 0;
        }

        .booking-form {
            .card-body {
                margin-bottom: 40px;

                .dob {
                    input {
                        margin-right: 20px;
                    }
                }
            }

            button.btn.big {
                font-size: 1.3em;
                font-weight: 500;
                padding: 23px 48px;
            }
        }
    }

    &.page.directory {
        .content {
            hr {
                margin: 58px 0;
            }

            .search-form {
                .input-group {
                    position: relative;

                    &:before {
                        position: absolute;
                        content: url('../images/search.svg');
                        top: 26px;
                        right: 24px;
                        z-index: 5;
                        width: 18px;
                        height: 20px;
                        display: block;
                    }

                    input[name="search"] {
                        padding-right: 40px;
                    }
                }
            }

            table.table {
                font-size: 1.1em;
                margin-top: 26px;
                margin-bottom: 39px;

                tbody {
                    td {
                        background: transparent;
                    }
                }
            }

            .pagination-holder {
                text-align: left;
            }
        }
    }
}

#footer {
    .social {
        margin-top: 15px;
        text-align: right;
        a {
            display: inline-block;
            margin-left: 10px;
            svg {
                width: 30px;
                height: 30px;
                rect {
                    fill: #07579F;
                }
            }
        }
    }
    .comfort {
        background: #07579F;
        padding: 30px 0 40px;

        img {
            max-height: 74px;
            margin-right: 45px;
            @include media-breakpoint-down(xs) {
                margin-right: 25px;
            }
        }

        p.text {
            color: white;
            font-weight: 500;
            font-size: 1.2em;
            padding-top: 13px;
            line-height: 22px;
        }

        .holder {
            padding-left: 53px;
            @include media-breakpoint-down(md) {
                padding-left: 0;
                margin-top: 24px;
            }

            span {
                margin-top: 15px;
                display: block;

                &.error {
                    color: #721c24;
                }
            }

            .input-group {

                .input-group-append {
                    //margin-left: -4px;
                    @include media-breakpoint-down(xs) {
                        display: block;
                        width: 100%;
                        margin-left: 0;
                        padding-top: 15px;
                    }

                    button.btn {
                        @include media-breakpoint-down(xs) {
                            width: 100%;
                            text-align: center;
                            padding: 13px 33px 13px;
                        }
                    }
                }

                input[type="text"] {
                    height: 50px;
                    color: $text;
                    text-indent: 13px;
                    border-bottom: none;
                    border-bottom-left-radius: 5px;
                    border-top-left-radius: 5px;

                    @include media-breakpoint-down(xs) {
                        display: block;
                        width: 100%;
                        border-radius: 5px;
                    }

                    &::placeholder {
                        color: $text;
                        opacity: 1;
                    }

                    &:-ms-input-placeholder {
                        color: $text;
                    }

                    &::-ms-input-placeholder {
                        color: $text;
                    }
                }

                .btn {
                    background: $lightBlue;
                    border-color: $lightBlue;
                    color: white;
                    border-bottom-left-radius: 5px;
                    border-top-left-radius: 5px;
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 6px 33px 8px;

                    &:hover {
                        @extend .opacity;
                    }
                }
            }
        }

    }

    .main {
        background: $darkestBlue;

        .top {
            padding-top: 87px;
            padding-bottom: 69px;
            @include media-breakpoint-down(md) {
                padding-bottom: 9px;
            }
            @include media-breakpoint-down(xs) {
                padding-bottom: 69px;
            }

            h5 {
                font-weight: 500;
                font-size: 1em;
                color: white;
                padding-bottom: 8px;
                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }

            ul {
                list-style: none;
                padding-left: 0;
                font-size: 0.95em;
                line-height: 27px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 60px;
                    text-align: center;
                }

                li {
                    a {
                        color: white;
                        text-decoration: none;

                        &:hover {
                            @extend .opacity;
                        }
                    }
                }
            }

            .contact {
                text-align: right;
                @include media-breakpoint-down(md) {
                    text-align: center;
                }

                a.tel {
                    padding-top: 14px;
                    margin-bottom: 4px;
                }

                a.tel, a.email {
                    display: block;
                    font-weight: 500;
                    color: white;
                    text-decoration: none;
                    font-size: 1.16em;

                    &:hover {
                        @extend .opacity;
                    }

                    img {
                        width: auto;
                        height: 18px;
                        margin-right: 15px;
                        margin-top: -3px;
                    }
                }
            }
        }

        .bottom {
            padding-top: 28px;
            border-top: 1px solid $grey;
            font-size: 0.77em;
            padding-bottom: 40px;
            color: white;
            @include media-breakpoint-down(md) {
                text-align: center;
            }

            ul {
                padding: 0;
                list-style: none;
                @include media-breakpoint-down(md) {
                    margin-top: 15px;
                }

                li {
                    border-right: 1px solid white;
                    padding-right: 8px;
                    margin-right: 8px;
                    line-height: 12px;
                    margin-top: 3px;

                    &:last-child {
                        border-right: none;
                        padding-right: 0;
                        margin-right: 0;
                    }

                    a {
                        color: white;
                        text-decoration: none;

                        &:hover {
                            @extend .opacity;
                        }
                    }
                }
            }
        }
    }
}

.card-body {
    padding: 3.1rem;
}

.bg-wms {
    background-color: $grey;
}

.quickLogin-card {
    background-color: $darkestBlue;
    color: white;

    .invalid-feedback {
        color: $yellow;
    }

    .is-invalid {
        border-color: $yellow;
    }

}

.search-highlight {
    background: #0C579F;
    color: white;
}

