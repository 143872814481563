//
// Custom Variables
//
// Here you can add your own variables overrides
//
// The file is included before any other variable file,
// so you can override them because they all use !default
// --------------------------------------------------

// For example, changing the primary color
// $primary: #a48ad4;

/* -------------------------------------------------------------------------- */
// All Bootstrap 4 Sass Mixins [Cheat sheet]
// Updated to Bootstrap v4.5.x
// @author https://anschaef.de
// @see https://github.com/twbs/bootstrap/tree/master/scss/mixins
/* -------------------------------------------------------------------------- */

$text: #333333;
$darkestBlue: #0A4361;
$darkerBlue: #0C579F;
$blue: #1170A0;
$lightBlue: #199AF0;
$grey: #EFF2F3;

$primary: $lightBlue;

//Grid variables
$grid-columns: 12;
$grid-gutter-width: 24px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$alert-border-radius: 0;

$modal-content-border-radius: 0;
